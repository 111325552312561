import React from 'react';

import { debug as createDebug, WS_TYPE } from '@lazy-app/common';

import { PermissionContext } from '../context/PermissionContext';
const debug = createDebug('lazy:native-logger');

const useFetchPermission = (id: string, browser: string) => {
  const { togglePermission } = React.useContext(PermissionContext);

  const fetchPermissions = React.useCallback(
    () => {
      window.WS.send({ channelId: id, data: { type: WS_TYPE.GET_WEB_PERMISSION, payload: browser } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    fetchPermissions();
    try {
      window.WS.listen({
        channelId: id,
        callback: (props: any) => {
          setTimeout(() => {
            window.WS.close();
            // 4 min timeout before closing connection
          }, 4 * 60 * 1000);
          if (props.type !== WS_TYPE.GET_WEB_PERMISSION || !props.payload[browser]) return;
          togglePermission?.(props.payload[browser]);
          if (Object.values(props.payload[browser]).some((v) => !v)) {
            if (props.type === WS_TYPE.GET_WEB_PERMISSION) {
              fetchPermissions();
            }
            return;
          }
        },
      });
    } catch (error) {
      debug(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPermissions, id]);

  return { fetchPermissions };
};

export default useFetchPermission;
