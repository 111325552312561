import React from 'react';

import { initPermission, PermissionContext } from './PermissionContext';
// Context has been created
// Provider
export default ({ children }: { children: React.ReactChild }) => {
  const [permission, setPermission] = React.useState(initPermission);
  const togglePermission = React.useCallback(
    (newPermission: any) => {
      setPermission({ ...permission, ...newPermission });
    },
    [setPermission, permission],
  );
  return (
    <PermissionContext.Provider value={{ ...permission, togglePermission }}>{children}</PermissionContext.Provider>
  );
};
