import { debug as debug$2, objectToFormData, pipe, sanitizeSlackEmoji, isEmoji, LazyPerformanceNames, isUrl, extractTextFromHtml, setLastUpdate } from "@lazy-app/common";
import { BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import axios from "axios";
import mem from "mem";
import pluralize from "pluralize";
import sanitizeHtml from "sanitize-html";
import { en, ParsingComponents, fr, de, ja, nl, pt, zh, ru } from "chrono-node";
const sentryInit = ({ appName, userEmail, userUuid, dsn, release, environment, version }) => {
  if (Sentry.getCurrentHub().getClient() !== void 0)
    return;
  Sentry.init({
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications."
    ],
    dsn,
    integrations: [new BrowserTracing({}), new Sentry.Replay()],
    release,
    environment,
    beforeSend: function(event) {
      var _a;
      try {
        let stacktrace = event.exception && ((_a = event.exception.values) == null ? void 0 : _a[0].stacktrace);
        if (stacktrace && stacktrace.frames) {
          stacktrace.frames.forEach(function(frame) {
            var _a2;
            const fragments = (_a2 = frame.filename) == null ? void 0 : _a2.split("/");
            if (!fragments)
              return;
            const filename = fragments.pop();
            const parentFolder = fragments.pop();
            const grandParentFolder = fragments.pop();
            frame.filename = `app://${grandParentFolder}/${parentFolder}/${filename}`;
          });
        }
      } catch (err) {
        debug$2("Sentry:beforeSend")("Error while rewriting paths", err);
      }
      return event;
    },
    replaysOnErrorSampleRate: 1,
    replaysSessionSampleRate: 0
  });
  if (version)
    Sentry.setTag("app", appName);
  Sentry.setUser({ email: userEmail, userUuid });
};
const segmentIdentify = ({
  userEmail,
  userUuid,
  appName,
  release,
  environment,
  version,
  subscriptionStatus
}) => {
  analytics.alias(userUuid);
  analytics.identify(userUuid, {
    name: userEmail,
    email: userEmail,
    $email: userEmail,
    appName,
    version,
    release,
    environment,
    subscriptionStatus
  });
};
const analyticsInit = ({
  appName,
  userEmail,
  userUuid,
  dsn,
  release,
  environment,
  version,
  subscriptionStatus
}) => {
  segmentIdentify({ appName, userEmail, userUuid, dsn, release, environment, version, subscriptionStatus });
  sentryInit({ appName, userEmail, userUuid, dsn, release, environment, version, subscriptionStatus });
};
const getErrorMessage = (error, message) => {
  if (typeof error === "string")
    return error;
  if (typeof error === "object" && error["message"])
    return error == null ? void 0 : error.message;
  return message || "";
};
const sendErrorToAnalytics = (error, message) => {
  try {
    debug$2("Error")(error);
    if (error instanceof Error) {
      sendErrorToSentry(error, message);
    } else {
      try {
        const e = new Error(getErrorMessage(error, message));
        e.stack = error == null ? void 0 : error.stack;
        throw e;
      } catch (e) {
        sendErrorToSentry(e, message);
      }
    }
  } catch (err) {
  }
};
const sendErrorToSentry = (error, message) => {
  Sentry.captureException(error, {
    tags: {
      message: message || ""
    }
  });
};
const sendTrackToAnalytics = (data) => {
  try {
    analytics.track(data.message, data.properties);
  } catch (err) {
    sendErrorToAnalytics(err, "sendTrackToAnalytics");
  }
};
const loadSegment = (REACT_APP_SEGMENT_IO_DNS) => {
  const analytics2 = window.analytics = window.analytics || {};
  if (!analytics2.initialize) {
    if (analytics2.invoked)
      window.console && console.error && console.error("Segment snippet included twice.");
    else if (analytics2) {
      analytics2.invoked = true;
      analytics2.methods = [
        "trackSubmit",
        "trackClick",
        "trackLink",
        "trackForm",
        "pageview",
        "identify",
        "reset",
        "group",
        "track",
        "ready",
        "alias",
        "debug",
        "page",
        "once",
        "off",
        "on",
        "addSourceMiddleware",
        "addIntegrationMiddleware",
        "setAnonymousId",
        "addDestinationMiddleware"
      ];
      analytics2.factory = function(e2) {
        return function() {
          var t = Array.prototype.slice.call(arguments);
          t.unshift(e2);
          analytics2.push(t);
          return analytics2;
        };
      };
      for (var e = 0; e < analytics2.methods.length; e++) {
        var key = analytics2.methods[e];
        analytics2[key] = analytics2.factory(key);
      }
      analytics2.load = function(key2, e2) {
        var t = document.createElement("script");
        t.type = "text/javascript";
        t.async = true;
        t.src = "https://cdn.segment.com/analytics.js/v1/" + key2 + "/analytics.min.js";
        var n = document.getElementsByTagName("script")[0];
        n.parentNode.insertBefore(t, n);
        analytics2._loadOptions = e2;
      };
      analytics2._writeKey = REACT_APP_SEGMENT_IO_DNS;
      analytics2.SNIPPET_VERSION = "4.15.3";
      analytics2.load(REACT_APP_SEGMENT_IO_DNS);
    }
  }
};
const analyticsInitInterface = ({
  appName,
  userEmail,
  userUuid,
  dsn,
  release,
  environment,
  version,
  subscriptionStatus
}) => analyticsInit({ appName, userEmail, userUuid, dsn, release, environment, version, subscriptionStatus });
const sendErrorToAnalyticsInterface = (error, message) => sendErrorToAnalytics(error, message);
const sendTrackToAnalyticsInterface = (data) => sendTrackToAnalytics(data);
const loadAnalyticsInterface = (REACT_APP_SEGMENT_IO_DNS) => {
  if (!REACT_APP_SEGMENT_IO_DNS)
    return;
  return loadSegment(REACT_APP_SEGMENT_IO_DNS);
};
const exportEnvVariablesToWindow = (envVariables) => {
  if (typeof window === "undefined")
    return;
  window.LAZY_SHARED_ENV_VARIABLES = envVariables;
};
const getEnvFromWindow = () => {
  var _a;
  if (typeof window === "undefined")
    return {};
  return (_a = window.LAZY_SHARED_ENV_VARIABLES) != null ? _a : {};
};
const debug$1 = debug$2("apiInterface");
const apiUrl = () => {
  const { API_URL } = getEnvFromWindow();
  return API_URL || "https://localhost";
};
const isNetworkErrorResponse = (e) => (e == null ? void 0 : e.message) === "Network Error";
const isUnauthorizedResponse = (e) => {
  var _a, _b;
  return ((_b = e == null ? void 0 : e.status) != null ? _b : (_a = e == null ? void 0 : e.request) == null ? void 0 : _a.status) === 401;
};
const isForbiddenResponse = (e) => {
  var _a, _b;
  return ((_b = e == null ? void 0 : e.status) != null ? _b : (_a = e == null ? void 0 : e.request) == null ? void 0 : _a.status) === 403;
};
const isNotFoundResponse = (e) => {
  var _a, _b;
  return ((_b = e == null ? void 0 : e.status) != null ? _b : (_a = e == null ? void 0 : e.request) == null ? void 0 : _a.status) === 404;
};
const isDuplicatedResponse = (e) => (e == null ? void 0 : e.description) === "uuid: DUPLICATED";
const isInternalServerErrorResponse = (e) => {
  var _a, _b;
  return ((_b = e == null ? void 0 : e.status) != null ? _b : (_a = e == null ? void 0 : e.request) == null ? void 0 : _a.status) === 500;
};
const MISSING_REFRESH_TOKEN_ERROR = "Missing refresh token";
const isMissingRefreshTokenError = (err) => (err == null ? void 0 : err.message) === MISSING_REFRESH_TOKEN_ERROR;
const isLoggedOutError = (err) => isMissingRefreshTokenError(err) || isUnauthorizedResponse(err);
const ODIN_TOKENS_STORE_KEY = "odin_tokens";
const REFRESH_TOKEN_URL = "/api/token/refresh";
const createApiInterface = ({
  baseURL,
  onTokensRefreshed,
  store
}) => {
  const client = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json"
    }
  });
  client.interceptors.request.use((config) => {
    const odinTokens = getOdinTokens();
    if (odinTokens) {
      config.headers.set("Authorization", `Bearer ${odinTokens.access_token}`);
    }
    return config;
  });
  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
      if (isUnauthorizedResponse(error) && (error == null ? void 0 : error.config) && !((_b = (_a = error == null ? void 0 : error.config) == null ? void 0 : _a.url) == null ? void 0 : _b.startsWith(REFRESH_TOKEN_URL))) {
        const {
          data: { access_token }
        } = await refreshTokens();
        error.config.headers.set("Authorization", `Bearer ${access_token}`);
        return client(error.config);
      }
      const apiError = {
        ...error,
        code: (_e = error == null ? void 0 : error.code) != null ? _e : (_d = (_c = error == null ? void 0 : error.response) == null ? void 0 : _c.data) == null ? void 0 : _d.code,
        message: (_f = error == null ? void 0 : error.message) != null ? _f : "",
        stack: error == null ? void 0 : error.stack,
        method: (_g = error == null ? void 0 : error.config) == null ? void 0 : _g.method,
        status: (_h = error == null ? void 0 : error.response) == null ? void 0 : _h.status,
        description: (_j = (_i = error == null ? void 0 : error.response) == null ? void 0 : _i.data) == null ? void 0 : _j["hydra:description"]
      };
      return Promise.reject(apiError);
    }
  );
  const getOdinTokens = () => {
    var _a;
    const odinTokens = (_a = store.get(ODIN_TOKENS_STORE_KEY)) != null ? _a : void 0;
    debug$1("getOdinTokens: store.get", { ODIN_TOKENS_STORE_KEY, odinTokens });
    if (odinTokens) {
      try {
        return JSON.parse(odinTokens);
      } catch (err) {
      }
    }
    return void 0;
  };
  const setOdinTokens = (odinTokens) => {
    if (odinTokens) {
      store.set(ODIN_TOKENS_STORE_KEY, JSON.stringify(odinTokens));
      debug$1("setOdinTokens: store.set", { ODIN_TOKENS_STORE_KEY, odinTokens });
    } else {
      store.delete(ODIN_TOKENS_STORE_KEY);
      debug$1("setOdinTokens: store.delete", { ODIN_TOKENS_STORE_KEY });
    }
    onTokensRefreshed(odinTokens);
  };
  const initialize = () => {
    onTokensRefreshed(getOdinTokens());
  };
  const login = async ({ code }) => {
    const response = await client.post("/api/login_check", { code });
    setOdinTokens(response.data);
    return response;
  };
  const logout = () => {
    setOdinTokens(void 0);
  };
  const refreshTokens = mem(
    async () => {
      const odinTokens = getOdinTokens();
      if (!odinTokens)
        throw new Error(MISSING_REFRESH_TOKEN_ERROR);
      const response = await client.post(REFRESH_TOKEN_URL, {
        refresh_token: odinTokens.refresh_token
      });
      setOdinTokens(response.data);
      return response;
    },
    { maxAge: 5e3 }
  );
  const uploadAttachment = (attachment) => {
    return client.post("/attachments", objectToFormData(attachment), {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  };
  const getAttachment = (id) => {
    return client.get(`/attachments/${id}`);
  };
  const getAttachmentMedia = (id) => {
    return client.get(`/attachments/media/${id}`, {
      responseType: "blob",
      headers: {
        Accept: "*/*"
      }
    });
  };
  const postBilling = () => {
    return client.post(`/api/stripe_billing_portal`);
  };
  const getUser = (id) => {
    return client.get(`/users/${id}`);
  };
  const updateUser = (id, data) => {
    return client.put(`/users/${id}`, data);
  };
  const parseUrl = ({ url }) => {
    return client.get(`/api/parser?url=${url}`);
  };
  const getSlackConversation = ({ conversationTitle }) => {
    return client.get(`/api/slack/conversations?conversationTitle=${encodeURIComponent(conversationTitle)}`);
  };
  const postCompletion = (data) => {
    return client.post("/api/completion", data);
  };
  const getWorkspace = (id, attrs) => {
    if ((attrs == null ? void 0 : attrs.version) === 2) {
      return client.get(`/workspaces/2/${id}${(attrs == null ? void 0 : attrs.lastSyncUpdate) ? `?lastSyncUpdate=${attrs.lastSyncUpdate}` : ""}`);
    }
    return client.get(`/workspaces/${id}`);
  };
  const updateWorkspace = (id, data) => {
    return client.put(`/workspaces/${id}`, data);
  };
  const postSync = (data) => {
    return client.post(`/api/sync`, data);
  };
  const getTweet = (id) => {
    return client.get(`/api/tweets/${id}`);
  };
  const getThread = (id) => {
    return client.get(`/api/thread/${id}`);
  };
  const get = (url) => {
    return client.get(url);
  };
  const getPrompts = (query) => {
    return client.get(`/prompts${query}`);
  };
  const updatePrompt = (prompt) => {
    return client.put(`/prompts/${prompt.uuid}`, {});
  };
  const deletePrompt = (prompt) => {
    return client.delete(`/prompts/${prompt.uuid}`);
  };
  const createPrompt = (prompt) => {
    return client.post(`/prompts`, prompt);
  };
  const postPicture = (file) => {
    return client.post(`/api/picture`, file, {
      headers: {
        "Content-Type": file.type
      }
    });
  };
  return {
    getOdinTokens,
    setOdinTokens,
    initialize,
    login,
    logout,
    refreshTokens,
    uploadAttachment,
    getAttachment,
    getAttachmentMedia,
    postBilling,
    getUser,
    updateUser,
    parseUrl,
    getSlackConversation,
    postCompletion,
    getWorkspace,
    updateWorkspace,
    postSync,
    getTweet,
    getThread,
    get,
    getPrompts,
    updatePrompt,
    deletePrompt,
    createPrompt,
    postPicture
  };
};
const InMemoryStore = (() => {
  const store = {};
  return {
    get: (key) => {
      return store[key];
    },
    set: (key, item) => {
      store[key] = item;
    },
    delete: (key) => {
      delete store[key];
    }
  };
})();
const createApiInterfaceFromEnv$1 = ({
  store = InMemoryStore,
  onTokensRefreshed = () => {
  }
} = {}) => {
  return createApiInterface({ baseURL: apiUrl(), onTokensRefreshed, store });
};
const isNetworkError = (e) => (e == null ? void 0 : e.code) === "ECONNABORTED" || (e == null ? void 0 : e.message) === "Network Error";
const isNetworkErrorInterface = (e) => isNetworkError(e);
var isOnline = () => navigator.onLine;
const isOnlineInterface = () => isOnline();
const _loginInfo = (context) => {
  return console.info.bind(context);
};
const _loginError = (context) => {
  return console.error.bind(context);
};
const _log = (context) => {
  return console.log.bind(context);
};
const logInfo = typeof window !== "undefined" ? _loginInfo(window.console) : () => {
};
const logError = typeof window !== "undefined" ? _loginError(window.console) : () => {
};
const log = typeof window !== "undefined" ? _log(window.console) : () => {
};
const pluralizeIfNotANumber = ({ word, numberOfElement }) => !Number(word) ? pluralize(word, numberOfElement) : word;
const pluralizeInterface = ({
  contentToPluralize,
  numberOfElement
}) => {
  return contentToPluralize.split(" ").map((word) => pluralizeIfNotANumber({ word, numberOfElement })).join(" ");
};
const selfClosingTags = [
  "AREA",
  "BASE",
  "COL",
  "EMBED",
  "OEMBED",
  "IFRAME",
  "HR",
  "IMG",
  "INPUT",
  "LINK",
  "META",
  "PARAM",
  "SOURCE",
  "SECTION",
  "TRACK",
  "WBR",
  "VIDEO"
];
const trimEmptyLastAndFirstEmptyChildNodeInterface = (content) => {
  const trimLast = trimEmptyTrailChildNodeInterface(content, "last");
  return trimEmptyTrailChildNodeInterface(trimLast, "first");
};
const CHILD_TYPE = {
  first: "firstChild",
  last: "lastChild"
};
const trimEmptyTrailChildNodeInterface = (content, direction) => {
  const div = document.createElement("div");
  div.innerHTML = content;
  let child = div[CHILD_TYPE[direction]];
  while (child && shouldEmptyChildBeRemoved(child)) {
    div.removeChild(child);
    child = div[CHILD_TYPE[direction]];
  }
  return containsInlineContent(div) ? div.outerHTML : div.innerHTML;
};
const shouldEmptyChildBeRemoved = (child) => {
  var _a;
  return child instanceof Element && ((_a = child.textContent) == null ? void 0 : _a.trim()) === "" && !isOrContainsSelfClosingHtmlTag(child);
};
const containsInlineContent = (div) => !!div.innerHTML && ![...div.childNodes].find((d) => d.nodeName !== "#text");
const isOrContainsSelfClosingHtmlTag = (el) => selfClosingTags.includes(el.tagName) || selfClosingTags.some((tag) => el.getElementsByTagName(tag).length !== 0);
const excludedTagToBeEmpty = [
  "address",
  "article",
  "aside",
  "footer",
  "header",
  "hgroup",
  "main",
  "nav",
  "dd",
  "dl",
  "dt",
  "figcaption",
  "figure",
  "main",
  "pre",
  "abbr",
  "b",
  "bdi",
  "bdo",
  "cite",
  "code",
  "data",
  "dfn",
  "em",
  "i",
  "kbd",
  "q",
  "rb",
  "rp",
  "rt",
  "rtc",
  "ruby",
  "s",
  "samp",
  "small",
  "strong",
  "sub",
  "sup",
  "time",
  "u",
  "var",
  "wbr",
  "caption",
  "col",
  "colgroup",
  "table",
  "tbody",
  "td",
  "tfoot",
  "th",
  "thead",
  "tr"
];
const EXCLUDE_IF_EMPTY_AND_NODE_EMPTY = ["a"];
const UNWANTED_PARENT_OF_MAIN_CONTENT = [
  "DIV",
  "SECTION",
  "ASIDE",
  "MAIN",
  "BODY",
  "FOOTER",
  "ARTICLE",
  "FIGURE",
  "META",
  "TBODY",
  "TR",
  "TD"
];
const INLINE_TAGS = [
  "A",
  "B",
  "I",
  "U",
  "S",
  "EM",
  "DEL",
  "HIGHLIGHT",
  "MARK",
  "STRONG",
  "SPAN",
  "SUP",
  "SUB",
  "KBD",
  "Q",
  "TIME",
  "TT",
  "SMALL",
  "BIG",
  "BDO",
  "BR"
];
const BLOCK_LEVEL_TAGS = ["p", "div", "li", "ul", "ol", "h1", "h2", "h3", "h4", "h5", "h6"];
const defaultAllowedTags = sanitizeHtml.defaults.allowedTags.filter((tag) => tag !== "span");
const defaultAllowedAttributes = {
  a: ["href", "name", "target", "data-char"],
  img: ["src", "alt"],
  video: ["src", "width", "height"],
  source: ["src", "type"],
  oembed: ["url"],
  iframe: ["src", "alt", "width", "height", "frameborder", "allowfullscreen"],
  table: ["data-tagsearch-lang"],
  code: ["data-serialized-whitespace"],
  pre: ["data-serialized-whitespace"]
};
const lazyCK5EditorAllowedAttributes = {
  span: ["data-mention", "data-uuid", "class"],
  input: ["type", "checked", "disabled", "value"],
  section: ["data-id", "data-ext", "data-name"]
};
const maybeRemoveTagsIfEmpty = (frame) => {
  if (!!frame.text.trim())
    return false;
  if (excludedTagToBeEmpty.includes(frame.tag))
    return true;
  if (EXCLUDE_IF_EMPTY_AND_NODE_EMPTY.includes(frame.tag) && !frame.mediaChildren.length)
    return true;
  return false;
};
const maybeRemoveEdgeCase = (frame) => {
  var _a, _b, _c, _d, _e, _f;
  if ((_b = (_a = frame.attribs) == null ? void 0 : _a.class) == null ? void 0 : _b.includes("paywall-jump"))
    return true;
  if (frame.tag === "a" && frame.text.toLocaleLowerCase() === "share")
    return true;
  if ((_d = (_c = frame.attribs) == null ? void 0 : _c.class) == null ? void 0 : _d.includes("powered-by-substack"))
    return true;
  if (frame.tag === "table" && ((_f = (_e = frame.attribs) == null ? void 0 : _e.class) == null ? void 0 : _f.includes("image-wrapper")))
    return false;
  return false;
};
const removeUnwantedTags = (html, options = {}) => sanitizeHtml(html, {
  exclusiveFilter: function(frame) {
    if (maybeRemoveEdgeCase(frame))
      return true;
    return maybeRemoveTagsIfEmpty(frame);
  },
  allowedClasses: {
    a: ["reference"],
    code: ["language-*", "hljs-*"],
    table: ["highlight"],
    blockquote: ["tweet"],
    h1: ["clipContentTitle"],
    ...options.allowLazyCKEditorRules ? { "*": ["*"] } : {}
  },
  allowedAttributes: options.allowedAttributes ? {
    ...options.allowedAttributes,
    ...defaultAllowedAttributes,
    ...options.allowLazyCKEditorRules ? lazyCK5EditorAllowedAttributes : {}
  } : false,
  allowedIframeDomains: ["youtube.com"],
  allowedTags: defaultAllowedTags.concat(["img", "video", "source", "highlight", "input", "label", "del", "iframe", "oembed"]).concat(options.allowLazyCKEditorRules ? ["span"] : []),
  transformTags: {
    a: sanitizeHtml.simpleTransform("a", { target: "_blank" }),
    span: (_, attribs) => {
      var _a, _b, _c, _d;
      if ((_a = attribs.style) == null ? void 0 : _a.includes("font-weight:600")) {
        return {
          tagName: "strong",
          attribs: {}
        };
      }
      if ((_b = attribs.style) == null ? void 0 : _b.includes("font-style:italic")) {
        return {
          tagName: "em",
          attribs: {}
        };
      }
      if ((_c = attribs.style) == null ? void 0 : _c.includes("text-decoration:underline")) {
        return {
          tagName: "u",
          attribs: {}
        };
      }
      if ((_d = attribs.style) == null ? void 0 : _d.includes("text-decoration:line-through")) {
        return {
          tagName: "s",
          attribs: {}
        };
      }
      return { tagName: "span", attribs };
    }
  }
});
const isImgWrappedIntoParagraph = (div) => div.tagName === "P" && div.children.length === 1 && div.children[0].tagName === "IMG";
const isLegacyContextSection = (div) => div.tagName === "SECTION" && div.dataset.uuid;
const loopIntoChildToRemoveParentWithBlockChild = (div) => [...div.childNodes].map((child) => {
  var _a;
  if ((child == null ? void 0 : child.nodeType) !== Node.TEXT_NODE)
    return maybeRemoveParentWithBlockChild(child);
  return /^(\s)+$/.test(child.nodeValue) ? (_a = child.nodeValue) == null ? void 0 : _a.trim() : child.nodeValue;
}).join("");
const isNewsletter = (div) => {
  return div.tagName === "TABLE" && div.getAttribute("role") === "presentation";
};
const maybeTurnEmojiImgIntoEmojiText = (div) => {
  sanitizeSlackEmoji(div);
  [...div.querySelectorAll("img[data-emoji]")].forEach((child) => {
    var _a;
    if ((child == null ? void 0 : child.tagName) === "IMG" && child.dataset.emoji) {
      (_a = child.parentNode) == null ? void 0 : _a.replaceChild(document.createTextNode(child.dataset.emoji), child);
    }
  });
  [...div.querySelectorAll('span[role="img"][aria-label]')].forEach((child) => {
    var _a;
    const emoji = child.getAttribute("aria-label");
    if (emoji)
      (_a = child.parentNode) == null ? void 0 : _a.replaceChild(document.createTextNode(emoji), child);
  });
  [...div.querySelectorAll("img")].forEach((child) => {
    var _a;
    const alt = child.getAttribute("alt");
    if ((child == null ? void 0 : child.tagName) === "IMG" && alt && isEmoji(alt)) {
      (_a = child.parentNode) == null ? void 0 : _a.replaceChild(document.createTextNode(alt), child);
    }
  });
};
const isLinkWrappingBlockContentElement = (div) => (div == null ? void 0 : div.tagName) === "A" && div.children.length && [...div.children].find((c) => BLOCK_LEVEL_TAGS.includes(c.tagName.toLowerCase()));
const maybeRemoveParentWithBlockChild = (div) => {
  var _a, _b;
  const onlyContainsTextNodes = [...div.childNodes].every((c) => {
    var _a2;
    return c.nodeType === Node.TEXT_NODE || INLINE_TAGS.includes(c.tagName) && !((_a2 = c.children) == null ? void 0 : _a2.length);
  });
  if (((_a = div.style) == null ? void 0 : _a.display) === "none")
    return "";
  if (isLegacyContextSection(div))
    return div.outerHTML;
  if (isImgWrappedIntoParagraph(div))
    return div.innerHTML;
  if (isLinkWrappingBlockContentElement(div))
    return div.innerHTML;
  if (((_b = div.children) == null ? void 0 : _b.length) === 0 || !isNewsletter(div) && !UNWANTED_PARENT_OF_MAIN_CONTENT.includes(div.tagName) || !isNewsletter(div) && div.tagName === "DIV" && onlyContainsTextNodes)
    return div.outerHTML;
  if (div.childNodes.length >= 1)
    return loopIntoChildToRemoveParentWithBlockChild(div);
  return div.innerHTML.trim();
};
const maybeRemoveCarriageReturnInTag = (HTML) => HTML.replaceAll(/<head>((.|\n)*?)<\/head>/gi, " ").replaceAll(/<title>((.|\n)*?)<\/title>/gi, " ").replaceAll(/<figcaption>((.|\n)*?)<\/figcaption>/gi, "<p>$1</p>").replaceAll(/[\r\n]+(?=(?:(?!<p\b)[\s\S])*<\/p>)/gi, " ").replaceAll(/&nbsp;/gi, " ").replaceAll(/ /gi, " ");
const cleanUpUnWantedParentTags = (HTML) => {
  const div = document.createElement("div");
  div.innerHTML = maybeRemoveCarriageReturnInTag(HTML);
  maybeTurnEmojiImgIntoEmojiText(div);
  return maybeRemoveParentWithBlockChild(div);
};
const transformLineBreaks = (HTML) => {
  const div = document.createElement("div");
  div.innerHTML = HTML.replace(/\n|(<br\s*(\s+[^>]*?)?\/?>)/gi, "<fake></fake>");
  const fakeElements = div.getElementsByTagName("fake");
  Array.from(fakeElements).forEach((fake) => {
    var _a, _b, _c, _d, _e, _f;
    if (fake.closest("code") || fake.closest("pre")) {
      (_a = fake.parentElement) == null ? void 0 : _a.replaceChild(document.createTextNode("\n"), fake);
    }
    if (((_c = (_b = fake.parentElement) == null ? void 0 : _b.textContent) == null ? void 0 : _c.startsWith("\u2611")) || ((_e = (_d = fake.parentElement) == null ? void 0 : _d.textContent) == null ? void 0 : _e.startsWith("\u2610"))) {
      (_f = fake.parentElement) == null ? void 0 : _f.replaceChild(document.createTextNode(" "), fake);
    }
  });
  div.innerHTML = div.innerHTML.replaceAll(/><fake><\/fake></gi, "><");
  div.innerHTML = div.innerHTML.replaceAll(/^\s*(<fake><\/fake>)+/gi, "");
  div.innerHTML = div.innerHTML.replaceAll(/<fake><\/fake><+/gi, (match, offset, html) => {
    const succeedingTag = html.substring(offset + match.length, html.indexOf(">", offset + match.length));
    const tagName = succeedingTag.startsWith("/") ? succeedingTag.substring(1) : succeedingTag;
    return INLINE_TAGS.includes(tagName.toUpperCase()) ? match : "<";
  });
  div.innerHTML = div.innerHTML.replaceAll(/(<fake><\/fake>)+\s*$/gi, "");
  div.innerHTML = div.innerHTML.replaceAll(/><fake><\/fake>+/gi, (match, offset, html) => {
    let nearestPrecedingBlockHTMLTag = void 0;
    for (let i = offset; i >= 0; i--) {
      const char = html.charAt(i);
      if (char === "<") {
        const tag = html.substring(i + 1, html.indexOf(">", i));
        nearestPrecedingBlockHTMLTag = tag.startsWith("/") ? tag.substring(1) : tag;
        break;
      }
    }
    return nearestPrecedingBlockHTMLTag && INLINE_TAGS.includes(nearestPrecedingBlockHTMLTag.toUpperCase()) ? match : ">";
  });
  div.innerHTML = div.innerHTML.replaceAll(/(<fake><\/fake>)+/gi, (_, __, offset, html) => {
    let nearestPrecedingBlockHTMLTag = void 0;
    for (let i = offset; i >= 0; i--) {
      const char = html.charAt(i);
      if (char === "<") {
        const tag = html.substring(i + 1, html.indexOf(">", i));
        if (!tag.startsWith("/") && BLOCK_LEVEL_TAGS.includes(tag)) {
          nearestPrecedingBlockHTMLTag = tag;
          break;
        }
      }
    }
    return `</${nearestPrecedingBlockHTMLTag != null ? nearestPrecedingBlockHTMLTag : "div"}><${nearestPrecedingBlockHTMLTag != null ? nearestPrecedingBlockHTMLTag : "div"}>`;
  });
  return div.innerHTML;
};
const maybeFixLazyPDFSanitizer = (html) => {
  var _a;
  const div = document.createElement("div");
  div.innerHTML = html;
  const markedContent = (_a = div.querySelectorAll("span.markedContent")) != null ? _a : div.querySelectorAll('span[role="presentation"]');
  markedContent.forEach((span) => {
    var _a2;
    const p = document.createElement("p");
    p.innerHTML = span.innerHTML;
    (_a2 = span.parentNode) == null ? void 0 : _a2.replaceChild(p, span);
  });
  if (markedContent.length === 0)
    return div.innerHTML;
  const br = div.querySelectorAll('br[role="presentation"]');
  br.forEach((br2) => {
    var _a2;
    (_a2 = br2.parentNode) == null ? void 0 : _a2.replaceChild(document.createTextNode(" "), br2);
  });
  return div.innerHTML;
};
const sanitizeHtmlInterface = (html = "", options = { allowedAttributes: {} }) => {
  var _a;
  const combinedOptions = { ...options, allowedAttributes: (_a = options.allowedAttributes) != null ? _a : {} };
  const trimmedContentFromLastChild = pipe(cleanUpUnWantedParentTags, maybeFixLazyPDFSanitizer)(html);
  const sanitizeHTML = removeUnwantedTags(trimmedContentFromLastChild, combinedOptions);
  const trimLastChild = trimEmptyLastAndFirstEmptyChildNodeInterface(sanitizeHTML);
  const maybeWrapIntoBlock = isInlineTags(trimLastChild) ? `<div>${trimLastChild}</div>` : trimLastChild;
  const withoutUnwantedTags = removeUnwantedTags(maybeWrapIntoBlock, { ...combinedOptions, allowedAttributes: false });
  const maybeTransformLineBreaks = !combinedOptions.keepLineBreaks ? transformLineBreaks(withoutUnwantedTags) : withoutUnwantedTags;
  return maybeTransformLineBreaks;
};
function isInlineTags(html) {
  const div = document.createElement("div");
  div.innerHTML = html;
  return [...div.childNodes].every((child) => {
    return child.nodeType === 3 || INLINE_TAGS.includes(child.tagName);
  });
}
const sanitizeEmailFromTable = (content) => content.replaceAll(/(<|<\/)(tbody|table|td|tr|th|tfoot|thead|figcaption)(\s*>)/gm, "$1tagToRemove$3").replaceAll(/&lt;/gm, "").replace(/^(\s*\n){2,}/gm, "").replace(/\n/gm, "").replaceAll(/&gt;/gm, "");
const sanitizeEmailCleanUp = (content) => content.replace(/(<p>\W*<\/p>){2,}/gm, "<p></p>").replace(/(<br \/>\W*<br \/>){1,}/gm, "<br />").replaceAll(/\s*(<\/a>)/gm, "$1").replaceAll(/(<a\s*>)\s*/gm, "$1").trim();
const sanitizeForEmailContent = (content) => {
  const sanitized = sanitizeHtmlInterface(content, { keepLineBreaks: true });
  const piped = pipe(sanitizeEmailFromTable, sanitizeEmailCleanUp)(sanitized);
  return sanitizeHtmlInterface(piped, { keepLineBreaks: false });
};
const _START = "_start";
const _END = "_end";
class LazyPerformance {
  constructor(name) {
    this.name = "performance";
    this.start = _START;
    this.end = _END;
    this.initPerformanceMark = () => {
      performance.mark(this.start);
      performance.mark(this.end);
    };
    this.setStartPerformanceMark = () => performance.mark(this.start);
    this.setEndPerformanceMark = () => performance.mark(this.end);
    this.measurePerformanceMarks = () => performance.measure(this.name, this.start, this.end);
    this.getPerformanceHistoric = () => performance.getEntriesByName(this.name);
    this.name = name;
    this.start = this.name + _START;
    this.end = this.name + _END;
  }
}
const getLazyPerformanceInterface = (name) => new LazyPerformance(name);
const getAllPerformanceHistoryInterface = (lazyPerformanceName) => {
  const perfHistory = lazyPerformanceName.reduce((acc, name) => {
    const perf = new LazyPerformance(name);
    const history = perf.getPerformanceHistoric();
    return [...acc, ...history];
  }, []);
  return perfHistory.sort((a, b) => a.startTime - b.startTime);
};
const clearPerformanceInterface = () => {
  performance.clearMarks();
  performance.clearMeasures();
};
const logStartMeasureTimeInterface = (name) => console.time(name);
const logEndMeasureTimeInterface = (name) => console.timeEnd(name);
const changePageLocationPerformance = getLazyPerformanceInterface(LazyPerformanceNames.pageChange);
const startPageLocationPerformanceMeasurement = () => {
  changePageLocationPerformance.initPerformanceMark();
  return () => {
    changePageLocationPerformance.setStartPerformanceMark();
    logStartMeasureTimeInterface("page_change");
  };
};
const endPageLocationPerformanceMeasurement = () => {
  changePageLocationPerformance.setEndPerformanceMark();
  changePageLocationPerformance.measurePerformanceMarks();
  logEndMeasureTimeInterface("page_change");
};
const sendLastMeasurementToTracker = ({
  cardType,
  cardUuid
}) => {
  const measurements = getAllPerformanceHistoryInterface(Object.values(LazyPerformanceNames));
  const lastMeasurement = measurements.pop();
  if (lastMeasurement)
    sendTrackToAnalyticsInterface({
      message: "Page speed",
      properties: {
        duration: lastMeasurement.duration,
        cardType,
        cardUuid
      }
    });
};
const measureAsyncFunction = async (measureName, asyncFunction) => {
  logStartMeasureTimeInterface(measureName);
  let result = await asyncFunction();
  logEndMeasureTimeInterface(measureName);
  return result;
};
const getAiUrl = () => {
  const { LAZY_AI_URL } = getEnvFromWindow();
  return LAZY_AI_URL;
};
const getCompletion = async ({
  temperature,
  token,
  streamCallback,
  abortController,
  timeoutBeforeFirstByteMs,
  replyTimeoutMs,
  historyId,
  userUuid,
  document: document2,
  question,
  typeOfContext
}) => {
  return new Promise(async (resolve, reject) => {
    var _a;
    let timeoutBeforeFirstByte;
    let replyTimeout;
    const aiUrl = getAiUrl();
    if (!aiUrl) {
      if (abortController == null ? void 0 : abortController.signal.aborted)
        return;
      handleError(new Error("No AI URL defined : " + aiUrl));
      return void 0;
    }
    function handleError(err) {
      if (timeoutBeforeFirstByte)
        clearTimeout(timeoutBeforeFirstByte);
      if (replyTimeout)
        clearTimeout(replyTimeout);
      reject(err);
      abortController == null ? void 0 : abortController.abort();
    }
    function startTimeoutBeforeFirstByte() {
      if (!timeoutBeforeFirstByteMs)
        return;
      timeoutBeforeFirstByte = setTimeout(
        () => handleError(new Error("Timeout before first byte exceeded")),
        timeoutBeforeFirstByteMs
      );
    }
    function startTimeoutReply() {
      if (!replyTimeoutMs)
        return;
      replyTimeout = setTimeout(() => handleError(new Error("Reply timeout exceeded")), replyTimeoutMs);
    }
    startTimeoutBeforeFirstByte();
    const response = await fetch(`${aiUrl}/get_completion_stream`, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      method: "POST",
      mode: "cors",
      cache: "default",
      body: JSON.stringify({
        question,
        historyId,
        userUuid,
        document: document2,
        temperature,
        typeOfContext
      }),
      signal: abortController == null ? void 0 : abortController.signal
    }).catch((err) => {
      if (abortController == null ? void 0 : abortController.signal.aborted)
        return;
      handleError(err);
      return;
    });
    if ((response == null ? void 0 : response.status) && (response == null ? void 0 : response.status) >= 400) {
      const error = new Error("API error");
      error.status = response.status;
      handleError(error);
      return;
    }
    const reader = (_a = response == null ? void 0 : response.body) == null ? void 0 : _a.pipeThrough(new TextDecoderStream()).getReader();
    if (!reader)
      return;
    let wholeText = "";
    startTimeoutReply();
    while (true) {
      const { value, done } = await reader.read();
      if (value) {
        const chunks = value.split("\n");
        for (const chunk of chunks) {
          const trimmedChunk = chunk.trim();
          if (!!trimmedChunk && trimmedChunk !== "[DONE]") {
            const res = JSON.parse(trimmedChunk);
            if (!res.text)
              break;
            if (res.error) {
              handleError(new Error(res.error.message));
              break;
            } else {
              const text = res.text;
              if (text) {
                wholeText += text;
                streamCallback == null ? void 0 : streamCallback(text);
              }
            }
          }
        }
      }
      if (done)
        break;
    }
    if (replyTimeout)
      clearTimeout(replyTimeout);
    resolve(wholeText);
  });
};
const callToLazyApi = async ({ document: document2, apiInterface: apiInterface2 }) => {
  try {
    const {
      data: { html }
    } = await apiInterface2.parseUrl({ url: document2 });
    if (!html) {
      return document2;
    }
    const text = extractTextFromHtml(html);
    if (!text) {
      return document2;
    }
    return text;
  } catch {
    return document2;
  }
};
const resolveAsyncPromptParameters = async ({
  document: document2,
  apiInterface: apiInterface2,
  articleParserInterface
}) => {
  if (!document2 || !isUrl(document2)) {
    return document2;
  }
  try {
    const { content } = await articleParserInterface([document2]);
    if (!content)
      return callToLazyApi({ document: document2, apiInterface: apiInterface2 });
    return content;
  } catch {
    return callToLazyApi({ document: document2, apiInterface: apiInterface2 });
  }
};
const getAppIdInterface = () => {
  var _a;
  return (_a = localStorage == null ? void 0 : localStorage.getItem("appId")) != null ? _a : void 0;
};
en.casual.parsers.push({
  pattern: () => {
    return /\bChristmas\b/i;
  },
  extract: (context) => {
    return new ParsingComponents(context.reference, {
      day: 25,
      month: 12
    }).addTag("casualReference/christmas");
  }
});
en.casual.parsers.push({
  pattern: () => {
    return /^(tom|tomo|tomor|tomorr|tomorro)\b/i;
  },
  extract: (context) => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    return new ParsingComponents(context.reference, {
      day: tomorrow.getDate(),
      month: tomorrow.getMonth() + 1,
      year: tomorrow.getFullYear()
    }).addTag("casualReference/tomorrow");
  }
});
en.casual.parsers.push({
  pattern: () => {
    return /^(to|tod|toda)\b/i;
  },
  extract: (context) => {
    const today = new Date();
    return new ParsingComponents(context.reference, {
      day: today.getDate(),
      month: today.getMonth() + 1,
      year: today.getFullYear()
    }).addTag("casualReference/today");
  }
});
const PARSERS = [en, fr, de, ja, nl, pt, zh, ru];
const parse = (text) => {
  for (const parser of PARSERS) {
    const results = parser.parse(text);
    if (results.length > 0)
      return results;
  }
  return [];
};
const parseDate = (text) => {
  return parse(text)[0];
};
const getCasualText = (parsedComponents) => {
  const tags = parsedComponents.tags();
  const translations = [
    ["casualReference/today", "Today"],
    ["casualReference/yesterday", "Yesterday"],
    ["casualReference/tomorrow", "Tomorrow"],
    ["casualReference/now", "Now"]
  ];
  for (const [tag, casualText] of translations) {
    if (tags.has(tag))
      return casualText;
  }
  return void 0;
};
var dateInterface = {
  parse,
  parseDate,
  getCasualText
};
const getYoutubeVideoThumbnail = async (url) => {
  try {
    const params = new Proxy(new URLSearchParams(url.split("?").pop()), {
      get: (searchParams, prop) => searchParams.get(prop)
    });
    const id = params["v"];
    if (!id)
      return void 0;
    const thumbnailUrl = `https://i.ytimg.com/vi_webp/${id}/maxresdefault.webp`;
    try {
      const res = await axios.get(thumbnailUrl);
      if (res.status === 200)
        return thumbnailUrl;
    } catch {
      return `https://i.ytimg.com/vi_webp/${id}/hqdefault.webp`;
    }
  } catch {
    return void 0;
  }
};
const getFaviconUrl = ({ apiUrl: apiUrl2, url }) => {
  const { FAVICON_URL } = getEnvFromWindow();
  return `${FAVICON_URL}?url=${encodeURIComponent(url != null ? url : "")}`;
};
const createApiInterfaceFromEnv = () => ({
  getOdinTokens: () => void 0,
  setOdinTokens: () => {
  },
  initialize: () => {
  },
  login: () => Promise.resolve({}),
  logout: () => {
  },
  refreshTokens: () => Promise.resolve({}),
  uploadAttachment: (data) => Promise.resolve({ data }),
  getAttachment: () => Promise.resolve({}),
  getAttachmentMedia: () => Promise.resolve({}),
  postBilling: () => Promise.resolve({}),
  getUser: () => Promise.resolve({}),
  updateUser: () => Promise.resolve({}),
  parseUrl: () => Promise.resolve({}),
  getSlackConversation: () => Promise.resolve({}),
  postCompletion: () => Promise.resolve({}),
  getWorkspace: () => Promise.resolve({}),
  updateWorkspace: () => Promise.resolve({}),
  postSync: (data) => {
    const response = data.map((item) => {
      var _a;
      if (item.action === "DELETE") {
        const response3 = {
          type: item.type,
          uuid: typeof item.value === "string" ? item.value : "",
          workspaceUuid: "workspace1"
        };
        return response3;
      }
      const response2 = {
        type: "Card",
        uuid: typeof item.value === "string" ? item.value : item.value.uuid,
        lastUpdate: setLastUpdate(),
        value: {
          metadata: void 0,
          ...item.value,
          attachments: ((_a = item.value.attachments) != null ? _a : []).map(
            (attachment) => ({ uuid: attachment.split("/").pop() })
          ),
          "@type": "Card",
          workspace: `/workspaces/${item.value.workspaceUuid}`
        }
      };
      return response2;
    });
    return Promise.resolve({ data: response });
  },
  getTweet: () => Promise.resolve({}),
  getThread: () => Promise.resolve({}),
  get: () => Promise.resolve({ data: { isSaved: true } }),
  getPrompts: () => Promise.resolve({}),
  updatePrompt: () => Promise.resolve({}),
  deletePrompt: () => Promise.resolve({}),
  createPrompt: () => Promise.resolve({}),
  postPicture: () => Promise.resolve({})
});
var apiInterface = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  createApiInterfaceFromEnv
}, Symbol.toStringTag, { value: "Module" }));
var mock = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  apiInterface
}, Symbol.toStringTag, { value: "Module" }));
const debug = debug$2("LazyEvent");
function subscribe({
  eventName,
  listener
}) {
  document.addEventListener(eventName, listener);
  debug(`subscribe:${eventName}`);
  return () => unsubscribe({ eventName, listener });
}
function unsubscribe({
  eventName,
  listener
}) {
  document.removeEventListener(eventName, listener);
  debug(`unsubscribe:${eventName}`);
}
function publish({ eventName, detail }) {
  const event = new CustomEvent(eventName, { detail });
  document.dispatchEvent(event);
  debug(`publish:${eventName}`, detail);
}
const initLazyEvent = () => {
  window.lazyEvent = { publish, subscribe, unsubscribe };
};
export { LazyPerformance, ODIN_TOKENS_STORE_KEY, analyticsInitInterface, apiUrl, clearPerformanceInterface, createApiInterfaceFromEnv$1 as createApiInterfaceFromEnv, dateInterface, endPageLocationPerformanceMeasurement, exportEnvVariablesToWindow, getAiUrl, getAllPerformanceHistoryInterface, getAppIdInterface, getCompletion, getEnvFromWindow, getFaviconUrl, getLazyPerformanceInterface, getYoutubeVideoThumbnail, initLazyEvent, isDuplicatedResponse, isForbiddenResponse, isInternalServerErrorResponse, isLoggedOutError, isNetworkError, isNetworkErrorInterface, isNetworkErrorResponse, isNotFoundResponse, isOnlineInterface, isUnauthorizedResponse, loadAnalyticsInterface, log, logEndMeasureTimeInterface, logError, logInfo, logStartMeasureTimeInterface, measureAsyncFunction, mock, pluralizeInterface, resolveAsyncPromptParameters, sanitizeEmailCleanUp, sanitizeEmailFromTable, sanitizeForEmailContent, sanitizeHtmlInterface, sendErrorToAnalyticsInterface, sendLastMeasurementToTracker, sendTrackToAnalyticsInterface, startPageLocationPerformanceMeasurement };
