import { WsWebToAppService } from '@lazy-app/common';
import { LazyThemeProvider } from '@lazy-app/design-system';

import PermissionContextProvider from './context/PermissionContextProvider';
import OnboardingPage from './pages/OnboardingPage';

window.WS = window.WS ?? WsWebToAppService(process.env.REACT_APP_ABLY_KEY || '');
function App() {
  return (
    <PermissionContextProvider>
      <LazyThemeProvider>
        <OnboardingPage />
      </LazyThemeProvider>
    </PermissionContextProvider>
  );
}

export default App;
