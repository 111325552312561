import AppleScriptImage from '../assets/apple_script.png';
import ArcMenu from '../assets/arc-menu.png';
import AutomationImage from '../assets/automation.png';
import SafariAppleScriptImage from '../assets/safari_apple_script.png';
import SafariDevelopMenu from '../assets/safari_develop_menu.png';
import { OnboardingPageStepType } from '../types/OnboardingPageStepType';
import openLazyApp from './openLazyApp';

export const onboardingSteps: {
  safari: OnboardingPageStepType[];
  chrome: OnboardingPageStepType[];
  arc: OnboardingPageStepType[];
} = {
  chrome: [
    {
      id: 'chromeAutomation',
      name: 'Lazy automation',
      showHeader: true,
      isDone: false,
      infos: [
        {
          title: 'Become one',
          desc:
            'Enable automation settings to add more context to your notes and close the distance between thought and action.',
          subSteps: [
            {
              desc: 'Allow Lazy to capture the URL in Chrome when you take a note',
              id: 'automation',
              isAuthorized: false,
            },
          ],
          type: 'automation',
          buttons: [{ title: 'Enable automation', id: 'chromeAutomation' }],
          isAuthorized: false,
        },
      ],
      type: 'automation',
      toastMessage: 'Almost There',
      hasChevronBehind: true,
      img: AutomationImage,
    },
    {
      id: 'chromeAppleEvents',
      name: 'appleScript',
      showHeader: true,
      isDone: false,
      infos: [
        {
          title: 'See the unseen',
          desc: 'Toggle to Apple events and allow Javascript to enrich YouTube, Twitter and additional content.',
          subSteps: [
            {
              desc: 'Allow Lazy to capture the URL in Chrome when you take a note',
              id: 'automation',
              isAuthorized: true,
            },
            {
              desc: 'Allow Lazy to execute Javascript in Chrome to enrich your captures',
              id: 'appleScript',
              isAuthorized: false,
            },
          ],
          buttons: [{ title: 'Open menu', id: 'open' }],
          type: 'chromeAutomationAndAppleEvents',
          isAuthorized: false,
        },
      ],
      img: AppleScriptImage,
      type: 'appleScript',
      onClickButtonIsAuthorized: () => {
        openLazyApp();
      },
    },
  ],
  safari: [
    {
      id: 'safariDevelopMenu',
      name: 'Develop Menu',
      isDone: false,
      showHeader: true,
      infos: [
        {
          title: 'See the unseen',
          desc: 'Toggle to Apple events and allow Javascript to enrich YouTube, Twitter and additional content.',
          type: 'safariDevelopMenu',
          buttons: [{ title: 'Open menu', id: 'open' }],
          isAuthorized: false,
        },
      ],
      type: 'safariDevelopMenu',
      hasChevronBehind: true,
      img: SafariDevelopMenu,
    },
    {
      id: 'safariJavascriptFromAppleEvents',
      name: 'Apple Events',
      isDone: false,
      showHeader: true,
      infos: [
        {
          title: 'See the unseen',
          desc: 'Toggle to Apple events and allow Javascript to enrich YouTube, Twitter and additional content.',
          buttons: [{ title: 'Allow Apple Events', id: 'event' }],
          type: 'safariAppleEvents',
          isAuthorized: false,
        },
      ],
      img: SafariAppleScriptImage,
      type: 'safariAppleEvents',
      onClickButtonIsAuthorized: () => {
        openLazyApp();
      },
    },
  ],
  arc: [
    {
      id: 'arcJavascriptFromAppleEvents',
      name: 'Apple Events',
      isDone: false,
      showHeader: true,
      infos: [
        {
          title: 'See the unseen',
          desc: 'Toggle to Apple events and allow Javascript to enrich YouTube, Twitter and additional content.',
          buttons: [{ title: 'Allow Apple Events', id: 'event' }],
          type: 'arcAppleEvents',
          isAuthorized: false,
        },
      ],
      img: ArcMenu,
      type: 'arcAppleEvents',
      onClickButtonIsAuthorized: () => {
        openLazyApp();
      },
    },
  ],
};
