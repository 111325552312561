import React from 'react';

import { PromptPermissionType, WS_TYPE } from '@lazy-app/common';

const usePrompt = () => {
  const promptPermission = React.useCallback(
    ({ type, id }: PromptPermissionType) => {
      if (!id) return;
      window.WS?.send({
        channelId: id,
        data: { type: WS_TYPE.PROMPT_PERMISSIONS, payload: { type } },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { promptPermission };
};

export default usePrompt;
