/* eslint-disable no-console */
import React, { useMemo, useRef, useState } from 'react';

import { mediaQueries } from '@lazy-app/common';
import {
  OnboardingBackground,
  OnboardingHeader,
  OnboardingPaper,
  OnboardingPaperDone,
} from '@lazy-app/shared-components';
import { styled } from '@mui/material';

import { PermissionContext, StoreVarKeyType, StoreVarType } from '../context/PermissionContext';
import { onboardingSteps } from '../params/onboardingSteps.params';
import { OnboardingPageStepType } from '../types/OnboardingPageStepType';
import useFetchPermission from '../use/useFetchPermission';
import usePrompt from '../use/usePrompt';

const Container = styled('div')`
  height: 100%;
  overflow-x: hidden;
  width: 100%;
  .paper_container {
    position: relative;
    width: 100%;
    height: 100%;

    & > div {
      width: 100%;
      height: 100%;
      &.paper.chromeAutomation,
      &.paper.arcAutomation,
      &.paper.safariDevelopMenu {
        position: absolute;
        top: 29px;
        left: -29px;
        z-index: 1;
        opacity: 1;
        ${mediaQueries('sm')`
            left: 0;
            top: 0;
        `};
      }
      &.paper {
        & > div {
          div[browser='safari'] {
            margin: 10px 0;
          }

          ${mediaQueries('sm')`
              display: flex;
              flex-direction: column;
          `};
          & > div {
            ${mediaQueries('sm')`
                flex: 1;
                display: flex;
                flex-direction: column;
            `};
            & > div {
              ${mediaQueries('sm')`
                  flex: 1;          
                  flex-direction: column;
              `};
              & > div {
                ${mediaQueries('sm')`
                  flex: 1;   
                  padding: 20px;       
              `};
                & > div:first-of-type {
                  ${mediaQueries('sm')`
                    display: none;          
                  `};
                }
              }
            }
          }
        }
      }
      &.paper img {
        opacity: 0;
        ${mediaQueries('sm')`
          display: none;
        `};
      }
      &.paper:first-of-type img {
        opacity: 1;
      }
      & div[browser='safari'].image_container {
        margin: 0;
        img {
          width: 100%;
        }
      }
    }
    .done {
      margin: 0 auto;
      ${mediaQueries('sm')`
          width: 80%;
      `};
      & > div {
        ${mediaQueries('sm')`
              display: flex;
        `};
      }
    }
  }
  .onboarding_cards {
    margin-top: -80px;
  }
`;

const OnboardingPage = () => {
  const context = React.useContext(PermissionContext);
  const [currentStepIndex, setCurrentStepIndex] = React.useState(1);
  const [hasUserValidated, setHasUserValidated] = React.useState(false);
  const { promptPermission } = usePrompt();
  const [contextIsDone, setContextIsDone] = useState<{ [key: string]: true }>({});
  const params = (new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  }) as any) as { [key: string]: string };
  // @ts-ignore
  const WsId = useRef(params.id);

  const browser = useMemo(() => {
    if (params.browser) return params.browser as keyof typeof onboardingSteps;
    if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) return 'safari';
    return 'chrome';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useFetchPermission(WsId.current, browser);

  const onboardingStepsForRender = React.useMemo(() => {
    return onboardingSteps[browser]
      .filter((s) => s.type)
      .map((step: OnboardingPageStepType) => ({
        ...step,
        isDone: (step.type && context[step.type as keyof StoreVarType]) || contextIsDone[step.id],
        infos: step.infos.map((i) => ({
          ...i,
          isAuthorized: step.type && context[step.type as keyof StoreVarType],
          subSteps: i.subSteps?.map((s) => ({ ...s, isAuthorized: !!(s.id && context[s.id as StoreVarKeyType]) })),
        })),
      }));
  }, [browser, context, contextIsDone]);

  const onboardingStepsNotDone = React.useMemo(() => {
    if ((hasUserValidated && browser === 'arc') || browser !== 'arc')
      return onboardingStepsForRender.filter((s) => !s.isDone);
    return onboardingStepsForRender;
  }, [browser, hasUserValidated, onboardingStepsForRender]);

  const selectStep = React.useCallback(
    (index: number) => {
      if (onboardingStepsForRender[index].isDone && browser !== 'arc') {
        return setCurrentStepIndex(index);
      }
    },
    [browser, onboardingStepsForRender],
  );

  const onNextStep = React.useCallback(
    (step: OnboardingPageStepType) => {
      // @ts-ignore
      if (step.type && context[step.type] && step.onClickButtonIsAuthorized && browser !== 'arc') {
        step.onClickButtonIsAuthorized();
        // @ts-ignore
      } else if (step.type && context[step.type]) {
        setContextIsDone((context) => {
          const newContext = { ...context };
          newContext[step.id] = true;
          return newContext;
        });
      } else if (!step.isDone) {
        promptPermission({ type: step.id, id: WsId.current });
      } else if (step.isDone) {
        setContextIsDone((context) => {
          const newContext = { ...context };
          newContext[step.id] = true;
          return newContext;
        });
      }
    },
    [browser, context, promptPermission],
  );

  return (
    <>
      <Container>
        <OnboardingBackground>
          <div className="onboarding_cards">
            <OnboardingHeader
              steps={onboardingStepsForRender}
              currentStepIndex={currentStepIndex}
              selectStep={(index: number) => {
                selectStep(index);
              }}
            />
            <div className="paper_container">
              {onboardingStepsNotDone.map((step) => (
                <div className={`paper ${step.id}`} key={step.id}>
                  <OnboardingPaper
                    step={step}
                    steps={onboardingStepsForRender}
                    currentStepIndex={currentStepIndex}
                    onNextStep={({ skip }: { skip?: boolean }) => {
                      setHasUserValidated(true);
                      onNextStep({ ...step, isDone: skip });
                    }}
                    isAuthorized={step.type && context[step.type as StoreVarKeyType]}
                    currentSubStepIndex={0}
                    browser={browser}
                  />
                </div>
              ))}
              {onboardingStepsNotDone.length === 0 && (browser !== 'arc' || hasUserValidated) && (
                <OnboardingPaperDone
                  redirectToLazy={() =>
                    onboardingSteps[browser][onboardingSteps[browser].length - 1]?.onClickButtonIsAuthorized?.()
                  }
                />
              )}
            </div>
          </div>
        </OnboardingBackground>
      </Container>
    </>
  );
};

export default OnboardingPage;
