import React from 'react';

export interface StoreVarType {
  automation: boolean;
  appleScript: boolean;
  safariDevelopMenu: boolean;
  safariAppleEvents: boolean;
  safariJavascriptFromAppleEvents: boolean;
  arcJavascriptFromAppleEvents: boolean;
  arcAppleEvents: boolean;
}

export type StoreVarKeyType = keyof StoreVarType;

type StoreFnType = {
  togglePermission: (permission: Partial<StoreVarType>) => void;
};

export type Store = StoreVarType & StoreFnType;

export const initPermission: StoreVarType = {
  appleScript: false,
  automation: false,
  safariDevelopMenu: false,
  safariAppleEvents: false,
  safariJavascriptFromAppleEvents: false,
  arcJavascriptFromAppleEvents: false,
  arcAppleEvents: false,
};

type Fn = {
  togglePermission?: (permission: Partial<StoreVarType>) => void;
};

export const PermissionContext = React.createContext<StoreVarType & Fn>({
  ...initPermission,
});
