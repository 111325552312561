const openLazyApp = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });
  // create link and click
  const link = document.createElement('a');
  link.target = '_blank';
  // @ts-ignore
  link.href = params?.redirectTo ?? 'lazy://';
  link.click();
};

export default openLazyApp;
